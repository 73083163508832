import { FormField } from "./types";
import { loadFormState } from "./form";
import { createAction, createReducer } from "@reduxjs/toolkit";

export interface NameState extends FormField {
  firstName: string;
  lastName: string;
}

export const updateFirstName = createAction<string>("form/update-first-name");
export const updateLastName = createAction<string>("form/update-last-name");
export const toggleNameVisiable = createAction("form/toggle-name");

const initialState: NameState = {
  firstName: "",
  lastName: "",
  isVisiable: true,
};

export const nameReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateFirstName, (state, action) => {
      state.firstName = action.payload;
    })
    .addCase(updateLastName, (state, action) => {
      state.lastName = action.payload;
    })
    .addCase(toggleNameVisiable, (state) => {
      state.isVisiable = !state.isVisiable;
    })
    .addCase(loadFormState, (_, action) => action.payload.name);
});
