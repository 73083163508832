import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AppWrapper } from "./styles";

const Form = lazy(() => import("./form"));
const View = lazy(() => import("./view/View"));
const Share = lazy(() => import("./view/Share"));

export const App = () => {
  return (
    <AppWrapper>
      <Router>
        <Switch>
          <Route exact path="/setting">
            <Suspense fallback={null}>
              <Form />
            </Suspense>
          </Route>
          <Route exact path="/s/:code">
            <Suspense fallback={null}>
              <Share />
            </Suspense>
          </Route>
          <Route exact path="/">
            <Suspense fallback={null}>
              <View />
            </Suspense>
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </AppWrapper>
  );
};
