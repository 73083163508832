import { FormField } from "./types";
import { loadFormState } from "./form";
import { createAction, createReducer } from "@reduxjs/toolkit";

export interface OrgState extends FormField {
  organization: string;
  title: string;
}

export const updateOrganization = createAction<string>(
  "form/update-organization"
);
export const updateTitle = createAction<string>("form/update-title");
export const toggleOrgVisiable = createAction("form/toggle-org");

const initialState: OrgState = {
  organization: "",
  title: "",
  isVisiable: true,
};

export const orgReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateOrganization, (state, action) => {
      state.organization = action.payload;
    })
    .addCase(updateTitle, (state, action) => {
      state.title = action.payload;
    })
    .addCase(toggleOrgVisiable, (state) => {
      state.isVisiable = !state.isVisiable;
    })
    .addCase(loadFormState, (_, action) => action.payload.org);
});
