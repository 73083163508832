import styled, { createGlobalStyle } from "styled-components";

export const AppWrapper = styled.div`
  width: 100%;
  max-width: 680px;
  padding: 20px;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    color: #333333;
    margin: 0px;
    font-size: 16px;
  }
  textarea:focus, input:focus, select:focus{
    outline: none;
  }
  #root {
    display: flex;
    justify-content: center;
  }
`;
