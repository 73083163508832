import { FormField } from "./types";
import { loadFormState } from "./form";
import { createAction, createReducer } from "@reduxjs/toolkit";

export interface EmailState extends FormField {
  work: string;
  home: string;
}

export const updateEmailWork = createAction<string>("form/update-email-work");
export const updateEmailHome = createAction<string>("form/update-email-home");
export const toggleEmailVisiable = createAction("form/toggle-email");

const initialState: EmailState = {
  work: "",
  home: "",
  isVisiable: true,
};

export const emailReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateEmailWork, (state, action) => {
      state.work = action.payload;
    })
    .addCase(updateEmailHome, (state, action) => {
      state.home = action.payload;
    })
    .addCase(toggleEmailVisiable, (state) => {
      state.isVisiable = !state.isVisiable;
    })
    .addCase(loadFormState, (_, action) => action.payload.email);
});
