import { FormField } from "./types";
import { loadFormState } from "./form";
import { createAction, createReducer } from "@reduxjs/toolkit";

export interface PhoneState extends FormField {
  work: string;
  home: string;
}

export const updatePhoneWork = createAction<string>("form/update-phone-work");
export const updatePhoneHome = createAction<string>("form/update-phone-home");
export const togglePhoneVisiable = createAction("form/toggle-phone");

const initialState: PhoneState = {
  work: "",
  home: "",
  isVisiable: true,
};

export const phoneReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updatePhoneWork, (state, action) => {
      state.work = action.payload;
    })
    .addCase(updatePhoneHome, (state, action) => {
      state.home = action.payload;
    })
    .addCase(togglePhoneVisiable, (state) => {
      state.isVisiable = !state.isVisiable;
    })
    .addCase(loadFormState, (_, action) => action.payload.phone);
});
