import { FormField } from "./types";
import { loadFormState } from "./form";
import { createAction, createReducer } from "@reduxjs/toolkit";

export interface AddressState extends FormField {
  work: string;
  home: string;
}

export const updateAddressWork = createAction<string>(
  "form/update-address-work"
);
export const updateAddressHome = createAction<string>(
  "form/update-address-home"
);
export const toggleAddressVisiable = createAction("form/toggle-address");

const initialState: AddressState = {
  work: "",
  home: "",
  isVisiable: true,
};

export const addressReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateAddressWork, (state, action) => {
      state.work = action.payload;
    })
    .addCase(updateAddressHome, (state, action) => {
      state.home = action.payload;
    })
    .addCase(toggleAddressVisiable, (state) => {
      state.isVisiable = !state.isVisiable;
    })
    .addCase(loadFormState, (_, action) => action.payload.address);
});
