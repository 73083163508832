import { combineReducers } from "redux";
import { nameReducer } from "./name";
import { orgReducer } from "./org";
import { emailReducer } from "./email";
import { phoneReducer } from "./phone";
import { addressReducer } from "./address";

export const formReducer = combineReducers({
  name: nameReducer,
  org: orgReducer,
  email: emailReducer,
  phone: phoneReducer,
  address: addressReducer,
});

export const rootReducer = combineReducers({
  form: formReducer,
});

export default rootReducer;
